@font-face {
  font-family: "skrew-font";
  src: local("skrew-font"), url(./skrew-font.woff2) format("woff2");
  /* other formats include: 'woff2', 'truetype, 'opentype','embedded-opentype', and 'svg' */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "skrew-font";
  src: local("skrew-font"), url(./skrew-font.woff2) format("woff2");
  /* other formats include: 'woff2', 'truetype, 'opentype','embedded-opentype', and 'svg' */
  font-weight: bold;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0px;
  margin: 0px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  align-items: center;
  background-color: #000000;
  color: white;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  letter-spacing: 2px;
}

.MuiButton-contained.Mui-disabled {
  color: #aaa !important;
  background: #f0f0f0 !important;
  text-decoration: line-through !important;
}
