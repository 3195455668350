.cover {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  background-size: cover;
  z-index: -1;
}

.card {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 50px;
}